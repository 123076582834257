<template>
    <div class="main">
        <div class="container">
            <div class="title">
                <h1>
                    Settings
                </h1>
            </div>
            <div class="row">
                <div class="col-md-8">
                    <div class="row">
                        <form class="col-md-12" @submit.prevent="saveSettings">
                            <div class="form-group row">
                                <label for="inputPassword7" class="col-sm-3 col-form-label">{{ getSettings.settings.form.payout }}:</label>
                                <div class="col-sm-5">
                                    <select class="custom-select" v-model="form.payout_method">
                                        <option selected>{{ getSettings.settings.form.select }}</option>
                                        <option :value="item.name" v-for="item in getSettings.settings.dropmenu">{{ item.value }}</option>                                  
                                    </select>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="inputPassword7" class="col-sm-3 col-form-label">Email:</label>
                                <div class="col-sm-5">
                                     <input v-model="form.email" type="email" class="form-control" id="inputPassword7">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="inputPassword6" class="col-sm-3 col-form-label" style="white-space: pre-line;line-height: 0.8;">{{ getSettings.settings.form.telephone }}:</label>
                                <div class="col-sm-5">
                                     <input type="tel" v-model="form.phone_number" class="form-control" id="inputPassword6" />
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-3 col-form-label"></label>
                                <div class="col-sm-9">
                                    <button type="submit" class="btn">Save</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="card">
                        <h4 class="card-header">{{ activeLanguage.title }}</h4>
                        <div class="card-body">
                            <p>{{ activeLanguage.text }}</p><br />
                            <table style="width: 100%">
                                <tr>
                                    <td colspan="2">
                                        <strong>{{ activeLanguage.helpdesk.title }}</strong>
                                    </td>
                                </tr>
                                <tr>
                                    <td>{{ activeLanguage.helpdesk.tel.prop }}</td><td>{{ activeLanguage.helpdesk.tel.val }}</td>
                                </tr>
                                <tr>
                                    <td>{{ activeLanguage.helpdesk.email.prop }}</td><td><a href="" class="btn btn-sm">{{ activeLanguage.helpdesk.email.val }}</a></td>
                                </tr>
                                <tr>
                                    <td colspan="2">
                                        <br /><strong>{{ activeLanguage.hours.title }}</strong>
                                    </td>
                                </tr>
                                <tr>
                                    <td>{{ activeLanguage.hours.wdProp }}</td><td>{{ activeLanguage.hours.wdVal }}</td>
                                </tr>
                                <tr>
                                    <td>{{ activeLanguage.hours.sat }}</td><td>{{ activeLanguage.hours.status }}</td>
                                </tr>
                                <tr>
                                    <td>{{ activeLanguage.hours.sun }}</td><td>{{ activeLanguage.hours.status }}</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {mapGetters} from  "vuex"
export default {
    data() {
        return {
            form: {}
        }
    },
    mounted() {
        this.getSetting()
    },
    methods: {
        getSetting() {
            billinkAxios.get('/app/reseller/settings/get')
                .then(({ data }) => {
                    this.form = data
                }).catch(err => console.log(err))
        },
        saveSettings() {
            billinkAxios.post('/app/reseller/settings/set', this.form)
                    .then(data => console.log(data))
                    .catch(err => console.log(err))
        }
    },
    computed: {
		...mapGetters({
            activeLanguage: 'getHelpDeskLanguage',
            getSettings: 'getResellerLanguage'
		})
	},
}
</script>